import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import { getMedia } from '../../utils/get-media.ts';

const Matterport = () => {
     const [loading, setLoading] = useState(true);
     const [selected, setSelected] = useState(null);
     const [data, setData] = useState({});
     const [slides, setSlides] = useState([]);
     const [etiqueta, setEtiqueta] = useState({});
     const [apartados, setApartados] = useState([]);
     const [industrias, setIndustrias] = useState([]);
     const [camaras, setCamaras] = useState([]);
     useEffect(() => {
          const fetchApartados = async () => {
               try {
                    const response = await fetch('https://darkturquoise-horse-959117.hostingersite.com/wp-json/wp/v2/servicio/249');
                    const data = await response.json();

                    const responseCamaras = await fetch('https://darkturquoise-horse-959117.hostingersite.com/wp-json/wp/v2/camara');
                    const dataCamaras = await responseCamaras.json();

                    // Si hay apartados en el ACF, los guardamos
                    if (data.acf) {
                         setData(data.acf);
                    }

                    // SLIDES
                    if (data.acf?.slides) {
                         const getEtiqueta = async () => {
                              const etiqueta = data.acf.slides.etiqueta;
                              const icono = await getMedia(etiqueta.icono);
                              return {
                                   icono: icono,
                                   texto: etiqueta.texto
                              };
                         }
                         setEtiqueta(await getEtiqueta());

                         const slidesData = await Promise.all(data.acf.slides.lista_de_slides.map(async (slide) => {
                              const imageId = slide.fondo;
                              let imageUrl = null;

                              if (imageId) {
                                   imageUrl = await getMedia(imageId);
                              }

                              return {
                                   title: slide.titulo,
                                   text: slide.texto,
                                   imageUrl: imageUrl || '../images/default.png', // Imagen por defecto si no hay imagen
                              };
                         }));

                         setSlides(slidesData);
                    }

                    // APARTADOS
                    if (data.acf?.apartados) {
                         const apartadosData = await Promise.all(data.acf.apartados.map(async (apartado) => {
                              const imageId = apartado.imagen;
                              let imageUrl = null;

                              if (imageId) {
                                   imageUrl = await getMedia(imageId);
                              }

                              return {
                                   ...apartado,
                                   imageUrl: imageUrl || '../images/default.png', // Imagen por defecto si no hay imagen
                              };
                         }));

                         setApartados(apartadosData);
                    }

                    // INDUSTRIAS Y DEMOS
                    if (data.acf?.industrias_y_demos) {
                         const industrias = await Promise.all(data.acf.industrias_y_demos.lista.map(async (industria) => {
                              const imageId = industria.imagen;
                              const maqueta = {
                                   delante: industria.maqueta.delante,
                                   atras: industria.maqueta.atras,
                              }

                              let imageUrl = null;
                              let maquetaUrl = {
                                   delante: null,
                                   atras: null,
                              };

                              if (imageId) {
                                   imageUrl = await getMedia(imageId);
                              }

                              if (maqueta.delante) {
                                   maquetaUrl.delante = await getMedia(maqueta.delante);
                              }

                              if (maqueta.atras) {
                                   maquetaUrl.atras = await getMedia(maqueta.atras);
                              }

                              return {
                                   ...industria,
                                   imageUrl: imageUrl || '../images/default.png', // Imagen por defecto si no hay imagen
                                   maquetaUrl: maquetaUrl,
                              };
                         }));

                         setIndustrias(industrias);
                    }

                    // CAMARAS
                    if (dataCamaras) {
                         const camaras = await Promise.all(dataCamaras.filter(camara => camara.acf.camara_recomendada).map(async (camara) => {
                              const imageId = camara.featured_media;
                              let imageUrl = null;

                              if (imageId) {
                                   imageUrl = await getMedia(imageId);
                              }

                              return {
                                   ...camara,
                                   imageUrl: imageUrl || '../images/default.png', // Imagen por defecto si no hay imagen
                              };
                         }));

                         setCamaras(camaras);
                    }

                    setLoading(false);
               } catch (error) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
               }
          };

          fetchApartados();
     }, []);

     useEffect(() => {
          // Inicia WOW.js para animaciones
          new WOW.WOW({
               live: false, // Si deseas detectar contenido cargado dinámicamente, cámbialo a true
          }).init();
     }, []);

     if (loading) {
          return (
               <div className='w-full h-full bg-black fixed top-0 z-[999999999] flex flex-col justify-center items-center'>
                    <img className='w-full h-[44px] object-contain' src='../images/icons/logo.svg' alt="" />
               </div>
          );
     }

     return (
          <div>
               <div className='bg-black wow animate__animated animate__fadeInDown' data-wow-duration="2s">
                    <div className='py-5'>
                         <img className='max-w-full mx-auto' src={slides[0].imageUrl} alt="" />
                    </div>
                    <div className='clip-diagonal-orange'>
                    </div>
                    <div className='clip-diagonal-white'>
                    </div>
               </div>
               <div className="pt-[40px] md:pt-[100px] flex max-md:flex-col justify-evenly items-center relative z-[10000] w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto">
                    <div className="w-full md:w-1/2 pr-5 relative before:absolute before:bottom-[-10px] before:left-[-15px] before:w-[30px] before:rounded-tl-[2px] before:bg-white before:h-1/2 after:content-[''] after:h-[30px] after:w-[30px] after:rotate-45 after:bg-white after:left-[-22px] after:top-[48%] after:absolute wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                         <img className=' max-w-full mx-auto' src="/images/matterport-explora.png" alt="" />
                    </div>
                    <div className='w-full md:w-1/2 max-w-full py-10 wow animate__animated animate__fadeInRight' data-wow-duration="2s">
                         <h2 className="w-full text-[18px] md:text-[32px] leading-[22px] md:leading-[32px] font-medium uppercase">
                              <span className="font-bold text-[#F89D38]">explora los beneficios</span><br />
                              de adquirir una cámara 3d
                         </h2>
                         <p className="w-full my-4 text-[14px] text-justify">
                              Los recorridos virtuales Matterport van más allá de lo visual. Son herramientas poderosas de planeación y operación que facilitan obtener planos en dwg, trabajos de ingeniería y remodelación.
                         </p>
                    </div>
               </div>
               <div className='pt-[100px] md:pt-[300px] overflow-hidden relative before:w-[180vw] before:h-[84vw] before:absolute before:top-[10vw] before:left-[-40%] before:rounded-tr-[9999px] before:rounded-tl-[9999px] before:z-[9999] before:content-[""] before:bg-[#D7D7D7] py-10'>
                    <div className='bg-[#D7D7D7] flex justify-center items-center z-[999999] relative'>
                         <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto py-10'>
                              <h2 className="text-[32px] text-black leading-[32px] pb-10 text-right font-medium uppercase wow animate__animated animate__fadeInDown [&_strong]:text-[#F89D38]" data-wow-duration="2s" dangerouslySetInnerHTML={{ __html: data.industrias_y_demos.titulo }} />
                              <div className='flex max-md:flex-col justify-between'>
                                   {industrias.map((industria, index) => (
                                        <div key={index} className="flip-container  !h-[550px] !w-[320px] max-w-full my-5 flex flex-col justify-center items-center" onMouseEnter={() => setSelected(0)} onMouseLeave={() => setSelected(null)}>
                                             <div className="flip-inner" data-wow-duration="2s">
                                                  <div className="flip-front flex flex-col justify-evenly items-center">
                                                       <h3 className="text-black text-center mt-4" dangerouslySetInnerHTML={{ __html: industria.titulo }} />
                                                       <p className="text-sm text-center" dangerouslySetInnerHTML={{ __html: industria.descripcion }} />
                                                       <img src={industria.maquetaUrl.delante} alt={industria.titulo} />
                                                  </div>
                                                  <div className="flip-back flex flex-col justify-evenly items-center">
                                                       <a href={industria.boton?.link} target="_blank" className="text-black bg-white py-1 px-3 my-3 text-center mb-4 inline-block" rel="noreferrer">{industria.boton?.texto}</a>
                                                       <img src={industria.imageUrl} alt={industria.titulo} />
                                                       <img src={industria.maquetaUrl.atras} alt={industria.titulo} className='w-full h-auto' />
                                                  </div>
                                             </div>
                                        </div>
                                   ))}
                              </div>
                         </div>
                    </div>
               </div>
               <div className='py-[20px] lg:py-[60px]'>
                    <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto'>
                         {apartados.map((apartado, index) => (
                              <>
                                   {index % 2 === 0 ? (
                                        <section key={index} className="flex flex-col-reverse md:flex-row items-center my-10">
                                             <div className="flex-1 flex justify-center relative before:absolute before:bottom-[-10px] before:left-[-15px] before:w-[30px] before:rounded-tr-[2px] before:bg-white before:h-1/2 after:content-[''] after:h-[30px] after:w-[30px] after:rotate-45 after:bg-white after:left-[-22px] after:top-[48%] after:absolute wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                                  <img src={apartado.imageUrl} alt="Tour Virtual" className="w-full h-auto rounded-[15px]" />
                                             </div>
                                             <div className="max-w-full flex-1 flex flex-col justify-center mt-6 md:mt-0 md:ml-8 wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                                                  <h2 className="text-lg md:text-[32px] leading-[24px] md:leading-[32px] font-medium uppercase [&_strong]:text-[#F89D38]" dangerouslySetInnerHTML={{ __html: apartado.titulo }} />
                                                  <p className="my-4" dangerouslySetInnerHTML={{ __html: apartado.descripcion }} />
                                             </div>
                                        </section>
                                   ) : (
                                        <section key={index} className="flex flex-col md:flex-row items-center my-10 lg:my-20">
                                             <div className="max-w-full flex-1 flex flex-col justify-center mt-6 md:mt-0 md:mr-8 wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                                  <h2 className="text-lg md:text-[32px] leading-[24px] md:leading-[32px] font-medium uppercase [&_strong]:text-[#F89D38]" dangerouslySetInnerHTML={{ __html: apartado.titulo }} />
                                                  <p className="my-4" dangerouslySetInnerHTML={{ __html: apartado.descripcion }} />
                                             </div>
                                             <div className="flex-1 flex justify-center relative before:absolute before:bottom-[-10px] before:right-[-15px] before:w-[30px] before:rounded-tr-[2px] before:bg-white before:h-1/2 after:content-[''] after:h-[30px] after:w-[30px] after:rotate-45 after:bg-white after:right-[-22px] after:top-[48%] after:absolute wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                                                  <img src={apartado.imageUrl} alt={apartado.titulo} className="w-full h-auto rounded-[15px]" />
                                             </div>
                                        </section>
                                   )}
                              </>
                         ))}
                    </div>
               </div>
               <div className="bg-[#D7D7D7] py-10">
                    <div className="w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto">
                         <h2 className="text-3xl font-medium text-center mb-10 wow animate__animated animate__fadeInDown" data-wow-duration="2s">
                              CÁMARAS
                              <span className="font-bold"> RECOMENDADAS</span>
                         </h2>
                         <div className="w-full flex max-md:flex-col justify-evenly gap-8 mb-10">
                              <div className="flex flex-col items-center text-center w-[324px] max-w-full bg-white py-8 px-5 rounded-[12px] wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                   <img src={camaras[0].imageUrl} alt={camaras[0].title.rendered} className="mb-4 h-[250px] w-full object-contain" />
                                   <h3 className="font-bold text-left w-full">
                                        Cámara <br />
                                        <span className="font-bold" dangerouslySetInnerHTML={{ __html: camaras[0].title.rendered }} />
                                   </h3>
                                   <ul className="text-left lista my-4">
                                        {camaras[0].acf.puntos_descripcion.map((desc, index) => (
                                             <li key={index} className='ml-4 text-[14px]' dangerouslySetInnerHTML={{ __html: desc.descripcion }} />
                                        ))}
                                   </ul>
                                   <a href={camaras[0].acf.boton.link} className="w-full text-[14px] bg-white text-black border border-black font-medium px-4 py-1">
                                        {camaras[0].acf.boton.texto || "PRECIO Y FICHA TÉCNICA"}
                                   </a>
                              </div>
                              <div className="flex flex-col items-center text-center w-[324px] max-w-full bg-white py-8 px-5 rounded-[12px] wow animate__animated animate__fadeInUp" data-wow-duration="2s">
                                   <img src={camaras[1].imageUrl} alt={camaras[1].title.rendered} className="mb-4 h-[250px] w-full object-contain" />
                                   <h3 className="font-bold text-left w-full">
                                        Cámara <br />
                                        <span className="font-bold" dangerouslySetInnerHTML={{ __html: camaras[1].title.rendered }} />
                                   </h3>
                                   <ul className="text-left lista my-4">
                                        {camaras[1].acf.puntos_descripcion.map((desc, index) => (
                                             <li key={index} className='ml-4 text-[14px]' dangerouslySetInnerHTML={{ __html: desc.descripcion }} />
                                        ))}
                                   </ul>
                                   <a href={camaras[1].acf.boton.link} className="w-full text-[14px] bg-white text-black border border-black font-medium px-4 py-1">
                                        {camaras[1].acf.boton.texto || "PRECIO Y FICHA TÉCNICA"}
                                   </a>
                              </div>
                              <div className="flex flex-col items-center text-center w-[324px] max-w-full bg-white py-8 px-5 rounded-[12px] wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                                   <img src={camaras[2].imageUrl} alt={camaras[2].title.rendered} className="mb-4 h-[250px] w-full object-contain" />
                                   <h3 className="font-bold text-left w-full">
                                        Cámara <br />
                                        <span className="font-bold" dangerouslySetInnerHTML={{ __html: camaras[2].title.rendered }} />
                                   </h3>
                                   <ul className="text-left lista my-4">
                                        {camaras[2].acf.puntos_descripcion.map((desc, index) => (
                                             <li key={index} className='ml-4 text-[14px]' dangerouslySetInnerHTML={{ __html: desc.descripcion }} />
                                        ))}
                                   </ul>
                                   <a href={camaras[2].acf.boton.link} className="w-full text-[14px] bg-white text-black border border-black font-medium px-4 py-1">
                                        {camaras[2].acf.boton.texto || "PRECIO Y FICHA TÉCNICA"}
                                   </a>
                              </div>
                         </div>
                    </div>
               </div>
               <div className="pt-10 pb-16">
                    <div className="w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto">
                         <div className="flex flex-col lg:flex-row justify-between items-center pt-10">
                              <div className="w-full lg:w-1/2 lg:pr-[30px] wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                   <h3 className="text-2xl font-medium mb-4">
                                        CONSÍGUE UNA <br />
                                        <span className="text-[#F89D38] font-bold">DEMOSTRACIÓN GRATUITA</span>
                                   </h3>
                                   <form className="space-y-4">
                                        <input type="text" placeholder="Nombre*" className="w-full px-4 py-2 border bg-[#F2EEEE]" />
                                        <input type="text" placeholder="Apellido*" className="w-full px-4 py-2 border bg-[#F2EEEE]" />
                                        <input type="text" placeholder="Empresa*" className="w-full px-4 py-2 border bg-[#F2EEEE]" />
                                        <input type="email" placeholder="Email*" className="w-full px-4 py-2 border bg-[#F2EEEE]" />
                                        <input type="tel" placeholder="Teléfono móvil*" className="w-full px-4 py-2 border bg-[#F2EEEE]" />
                                        <input type="text" placeholder="Industria*" className="w-full px-4 py-2 border bg-[#F2EEEE]" />
                                        <div className="flex max-md:flex-col justify-between">
                                             <button type="submit" className="w-full md:w-[48%] my-2 bg-[#F89D38] text-white font-medium px-4 py-2">ASESORÍA</button>
                                             <button type="submit" className="w-full md:w-[48%] my-2 bg-black text-white font-medium px-4 py-2">PRUEBA DEMO</button>
                                        </div>
                                   </form>
                              </div>
                              <img src="../images/matterport-image-4.png" alt="Tecnología 3D" className="w-full lg:w-1/2 h-auto rounded-[15px] wow animate__animated animate__fadeInRight" data-wow-duration="2s" />
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default Matterport;