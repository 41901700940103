import React, { useState } from 'react';

const Carousel = ({ slides, color }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleBulletClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full text-white">
      <div className='absolute inset-0 flex flex-col justify-center items-center gap-6'>
        <h1 className='text-white text-center text-[18px] md:text-[28px] font-bold' dangerouslySetInnerHTML={{ __html: slides[currentIndex]?.title }} />
        <div className='relative mx-auto w-[250px] md:w-[364px] mb-10 md:mb-48'>
          <div
            className="absolute before:absolute w-full h-[6px] md:h-[8px]"
            style={{
              backgroundImage: `linear-gradient(to right, ${color} 65%, #fff`,
            }}
          ></div>
        </div>
        <p className='text-white text-[28px] leading-[32px] md:text-[68px] mt-8 md:leading-[68px] mb-10 md:mb-48 text-center text-balance' dangerouslySetInnerHTML={{ __html: slides[currentIndex]?.text }} />
      </div>
      <img
        src={slides[currentIndex]?.imageUrl}
        alt={slides[currentIndex]?.title}
        className="w-full h-full object-cover min-h-[600px]"
      />
      <div className="absolute top-10 right-10 flex justify-center">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`w-3 h-3 mx-1 rounded-full cursor-pointer`}
            style={{
              backgroundColor: currentIndex === index ? color?.from : 'white',
            }}
            onClick={() => handleBulletClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
